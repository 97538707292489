<template>
  <section class='section'>
    <div class='container'>
      <form-builder
        :formData='data'
        formMethod='makeUserSignInForm'
        formSubmitMethod='monkey'
      ></form-builder>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    this.dataService = this.$dataService(this.$route.params.tenant);
  },
  data() {
    return {
      data: { password: 'secret', username: [{ id: 'DEREK', value: 'Derek' }, { id: 'CHARLIE', value: 'Charlie' }], email: 'tom@tom.com' },
    };
  },
};
</script>

<style>

</style>
